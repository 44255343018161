
    import 'jquery/dist/jquery.min.js';
    import "datatables.net-dt/js/dataTables.dataTables";
    import {
        api_url
    } from "@/ayar";
    import TableBaseComponent from "@/components/backoffice/table/TableBaseComponent.vue";
    import {
        useRoute
    } from 'vue-router';



    export default ({
        name: "MusteriveBayiri",
        components: {
            TableBaseComponent
        },

        

        data: () => {
            return {
                columns: [{
                        title: 'Adı Soyadı',
                        settings: {
                            data: 'name',
                            orderable: true
                        }
                    },

                    {
                        title: 'Paket',
                        settings: {
                            data: 'paid',
                            orderable: true,
                            searchable: true
                        }
                    },
                    {
                        title: 'Kariyer',
                        settings: {
                            data: 'price',
                            orderable: true
                        }
                    },
                    {
                        title: 'Toplam Ciro',
                        settings: {
                            data: 'action',
                            orderable: true
                        }
                    },

                ],
               
                }
        },
    });
